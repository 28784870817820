import HintIcon from 'src/assets/icons/hint.svg';
import LawIcon from 'src/assets/icons/law.svg';

const content = {
  title: 'bAV-Beratung und -Betreuung',
  headvisualHeadline: 'bAV-Beratung und -Betreuung',
  beratung: {
    headline: 'bAV-Beratung',
    paragraphs: [
      'Die NÜRNBERGER Business Beratungs GmbH (NBB) ist eine Konzerngesellschaft der NÜRNBERGER Versicherung.',
      'Als kompetenter Partner bietet sie Unternehmen, deren Mitarbeitern sowie Verbänden und Privatpersonen umfassende Beratung und Unterstützung in allen Fragen der betrieblichen Altersversorgung und den Personaldienstleistungen.',
      'Sie entwickelt hierfür firmenspezifische Lösungen, richtet neue Versorgungswerke ein, überarbeitet, modernisiert und passt bestehende an. Darüber hinaus verwaltet sie komplette Versorgungseinrichtungen.'
    ]
  },
  betreuung: {
    headline: 'bAV-Betreuung',
    paragraphs: [
      'Aufwendige Verwaltungsaufgaben binden oft Kapazitäten und erhöhen die Kosten. Durch die Beauftragung der NBB für die Verwaltung Ihres Versorgungswerkes, entfällt  dieser Verwaltungsaufwand.',
      'Seit Jahrzehnten verfügen wir über umfangreiche Erfahrungen in der Verwaltung unterschiedlichster Versorgungswerke. Wir ermitteln unverfallbare Anwartschaften bei Ausscheiden, Leistungen im Versorgungsfall und Daten für den Versorgungsausgleich.  Natürlich erstellen wir für Sie auch Leistungsausweise für Ihre Mitarbeiter.'
    ]
  },
  versorgungsordnung: {
    sectionHead: {
      topline: 'Die rechtssichere Versorgungsordnung',
      headline:
        'Die Versorgungsordnung – als Basis für eine betriebliche Altersversorgung',
      paragraph:
        'Die betriebliche Altersversorgung stellt einen wichtigen Bestandteil der Alterssicherung in Deutschland dar und ist durch eine Vielzahl von rechtlichen Vorschriften geregelt. Weite Teile der arbeitsrechtlichen Regelungen ergeben sich aus dem sogenannten Betriebsrentengesetz. Dieses Gesetz regelt jedoch nicht alles. Vielmehr sorgt es für einen arbeitsrechtlichen (Mindest-)Rahmen. Wie eine betriebliche Altersversorgung dann konkret ausgestaltet werden soll, ist den Arbeitsvertragsparteien insbesondere dem Arbeitgeber überlassen.'
    },
    gesetzHeadline: 'Was das Gesetz nicht regelt',
    gesetzItems: [
      'Festlegung der Zusageart, des Durchführungsweges und der Leistungsart',
      'Teilnahmeberechtigter Mitarbeiterkreis',
      'Höhe der Versorgungsleistung',
      'Festlegung des Versorgungsträgers',
      'Umwandelbare Gehaltsbestandteile',
      'Verfahren bei vorzeitigem Ausscheiden usw.'
    ],
    firstCard: {
      icon: HintIcon,
      headline: 'Klarheit schaffen und Risiken vermeiden.',
      paragraph:
        'Damit unrichtige oder nicht vorhandene Versorgungsregelungen nicht zu erheblichen finanziellen Risiken führen und um für die notwendige Klarheit zu sorgen, empfiehlt sich die Einrichtung einer Versorgungsordnung.'
    },
    secondCard: {
      icon: LawIcon,
      headline: 'Rechtssicherheit. Einfach. Digital.',
      paragraph:
        'Die NBB bietet in Zusammenarbeit mit renommierten Rechtsanwaltskanzleien bedarfsgerechte Lösungen und Dienstleistungen an, die Sie jederzeit online abrufen und in Auftrag geben können.',
      buttonLink: 'https://nbb-versorgungsordnung.digital',
      buttonText: 'Versorgungsordnung online erstellen'
    },
    chancenHeadine: 'Ihre Chancen',
    chancenItems: [
      'Optimale Nutzung der Vorteile der bAV',
      'Leichtere Durchführung und Abwicklung der bAV',
      'Förderung der Mitarbeiterzufriedenheit',
      'Erfüllung der Informationspflichten'
    ]
  },
  modernisierung: {
    topline: 'bAV-Beratung und -Betreuung',
    headline: 'Modernisierung/Optimierung von Versorgungswerken',
    paragraph:
      'Immer auf dem neuesten Stand. Im Bereich der betrieblichen Altersversorgung sehen sich Unternehmen laufend mit geänderten gesetzlichen Rahmenbedingungen konfrontiert. In einem Themengebiet fernab der eigenen Kernkompetenz ist es dabei nicht einfach, dauerhaft die ideale Lösung für sich und seine Mitarbeiter zu finden',
    dialog: {
      buttonText: 'Mehr anzeigen',
      buttonVariant: 'ghost',
      title: 'Modernisierung/Optimierung von Versorgungswerken',
      paragraphs: [
        'Bei Änderungen im Arbeitsrecht ist meist zu prüfen, ob die gewählten Formulierungen in der eigenen Versorgungsordnung noch sachgerecht sind. Steuerrechtliche Änderungen können die wirtschaftlichen Rahmenbedingungen soweit verändern, dass sowohl aus Arbeitgeber- als auch aus Arbeitnehmersicht größere Änderungen im Versorgungssystem sinnvoll sein können. Auch ist in Zeiten von Fachkräftemangel laufend zu prüfen, ob die gewählte Form der Ausgestaltung noch den gewünschten Grad an Attraktivität gewährleistet.',
        'Wenn Sie sich auch unsicher sind, ob Ihre Ausgestaltung der bAV noch modern und gesetzeskonform ist, können Sie sich gerne an uns wenden. Wir unterstützen Sie gerne, die ideale Lösung zu finden und gegebenenfalls benötigte oder gewünschte Änderungen in die Wege zu leiten.'
      ]
    }
  },
  portale: {
    firstCard: {
      headline: 'bAV-Verwaltung online – ab in die digitale Zukunft',
      paragraph:
        'Hier eine neue Vorschrift, da eine neue Regelung: Der Verwaltungsaufwand der betrieblichen Altersversorgung (bAV) dürfte auch in den nächsten Jahren nicht weniger werden. Vielen Arbeitgebern ist die Verwaltung der bAV mit zu viel Aufwand verbunden. Machen Sie es sich und Ihren Firmenkunden einfach und sorgen mit unseren Portallösungen für schnelle und digitale Verwaltungsabläufe.',
      buttonLink:
        '/unsere-dienstleistungen/bav-beratung-und-betreuung/xbav-und-epension',
      buttonText: 'Zu XEMPUS & ePension'
    },
    secondCard: {
      headline: 'Einfach online informieren.',
      paragraphs: [
        'Die betriebliche Altersversorgung (bAV) ist für Arbeitnehmer besonders vorteilhaft.',
        'Jedoch empfinden viele das Thema als schwierig und vermeiden es daher, sich damit zu beschäftigen. Das hat zur Folge, dass dieses interessante Angebot oft nicht wahrgenommen wird. ',
        'Das NÜRNBERGER Betriebsrenten-Portal wirkt dem entgegen und informiert Arbeitnehmer klar verständlich, strukturiert und mit vielen Grafiken. Darüber hinaus können zusätzliche Inhalte und Arbeitgeberinformationen wie beispielsweise Betriebsvereinbarungen eingebunden werden.'
      ],
      buttonLink:
        '/unsere-dienstleistungen/bav-beratung-und-betreuung/betriebsrenten-portal',
      buttonText: 'Zum Betriebsrenten-Portal'
    }
  }
};

export default content;
