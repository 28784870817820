import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  HalvedContent,
  ContentBox,
  Paragraphs,
  SectionHead,
  List,
  Grid,
  Button,
  Dialog
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/unsere-dienstleistungen/bav-beratung-und-betreuung';
import subpageNavigations from 'src/data/subpage-navigations';
import CrossIcon from 'src/assets/icons/cross.svg';

const BAVBeratungUndBetreuungPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.beratungUndBetreuung}
  >
    <section>
      <HalvedContent align="left">
        <BackgroundImage
          fluid={data.twoMenWithTablet.childImageSharp.fluid}
        />
        <ContentBox headline={content.beratung.headline}>
          <Paragraphs items={content.beratung.paragraphs} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <HalvedContent align="right" swapped>
        <BackgroundImage fluid={data.manWithGlasesAndPen.childImageSharp.fluid} />
        <ContentBox headline={content.betreuung.headline}>
          <Paragraphs items={content.betreuung.paragraphs} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="rechtssichere-versorgungsordnung">
      <SectionHead
        topline={content.versorgungsordnung.sectionHead.topline}
        headline={content.versorgungsordnung.sectionHead.headline}
        centered
      >
        <p>{content.versorgungsordnung.sectionHead.paragraph}</p>
      </SectionHead>
      <ContentBox
        background="light-blue"
        headline={content.versorgungsordnung.gesetzHeadline}
        single
      >
        <List
          items={content.versorgungsordnung.gesetzItems}
          icon={CrossIcon}
          columns={2}
        />
      </ContentBox>
      <Grid>
        <Card>
          <CardHeader
            headline={content.versorgungsordnung.firstCard.headline}
            parallel
          >
            <content.versorgungsordnung.firstCard.icon />
          </CardHeader>
          <p>{content.versorgungsordnung.firstCard.paragraph}</p>
        </Card>
        <Card>
          <CardHeader
            headline={content.versorgungsordnung.secondCard.headline}
            parallel
          >
            <content.versorgungsordnung.secondCard.icon />
          </CardHeader>
          <p>{content.versorgungsordnung.secondCard.paragraph}</p>
          <Button
            href={content.versorgungsordnung.secondCard.buttonLink}
            variant="peach"
            external
          >
            {content.versorgungsordnung.secondCard.buttonText}
          </Button>
        </Card>
      </Grid>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.redHairedWomanOutside.childImageSharp.fluid} />
        <ContentBox
          background="light-salmon"
          headline={content.versorgungsordnung.chancenHeadine}
        >
          <List items={content.versorgungsordnung.chancenItems} />
        </ContentBox>
      </HalvedContent>
    </section>
    <section
      id="modernisierung-von-versorgungswerken"
      className="bg-dark-blue text-white"
    >
      <SectionHead
        topline={content.modernisierung.topline}
        headline={content.modernisierung.headline}
      >
        <p>{content.modernisierung.paragraph}</p>
        <Dialog
          buttonText={content.modernisierung.dialog.buttonText}
          buttonVariant={content.modernisierung.dialog.buttonVariant}
          title={content.modernisierung.dialog.title}
        >
          <Paragraphs items={content.modernisierung.dialog.paragraphs} />
        </Dialog>
      </SectionHead>
    </section>
    <section id="portale" className="bg-light-blue">
      <Grid>
        <Card noShadow>
          <CardHeader headline={content.portale.firstCard.headline} />
          <p>{content.portale.firstCard.paragraph}</p>
          <Button
            href={content.portale.firstCard.buttonLink}
            variant="gray-blue"
          >
            {content.portale.firstCard.buttonText}
          </Button>
        </Card>
        <Card noShadow>
          <CardHeader headline={content.portale.secondCard.headline} />
          <Paragraphs items={content.portale.secondCard.paragraphs} />
          <Button
            href={content.portale.secondCard.buttonLink}
            variant="gray-blue"
          >
            {content.portale.secondCard.buttonText}
          </Button>
        </Card>
      </Grid>
    </section>
  </MainLayout>
);

export default BAVBeratungUndBetreuungPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-sitting-and-holding-a-mobile-phone-wide.jpg" }
    ) {
      ...image
    }
    twoMenWithTablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "two-men-with-tablet.jpg" }
    ) {
      ...image
    }
    manWithGlasesAndPen: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-glases-and-pen.jpg" }
    ) {
      ...image
    }
    redHairedWomanOutside: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "red-haired-woman-outside.jpg" }
    ) {
      ...image
    }
  }
`;
